import dayjs from 'dayjs';

export const defaultDateFormat = 'D MMM YYYY h:mm A';

export const numberFormat = (value: number): string => {
  return Math.floor(value).toLocaleString('th-TH');
};

export const dateFormat = (date: Date | string, format = defaultDateFormat) => {
  return dayjs(date).format(format);
};

export const startOf = (date: Date | string) => {
  return dayjs(date).startOf('date').toDate();
};

export const dateDiff = (date: Date | string, date2: Date | string) => {
  const d1 = new Date(date).getTime();
  const d2 = new Date(date2).getTime();
  return Math.abs(d2 - d1);
};

export const mergeDateTime = (date: Date | string, time: Date | string) => {
  const dateVal = new Date(date).getTime();
  const timeVal = dateDiff(startOf(time), time);
  return new Date(dateVal + timeVal);
};

export enum OrderStatus {
  Initial = 'Initial',
  WaitPayment = 'WaitPayment',
  WaitPartner = 'WaitPartner',
  ReadyForDrive = 'ReadyForDrive',
  Driving = 'Driving',
  ReturnCar = 'ReturnCar',
  Reject = 'Reject',
  Done = 'Done',
  Refunding = 'Refunding',
  Refund = 'Refund',
}

export enum OrderName {
  Initial = 'ออเดอร์ใหม่',
  WaitPayment = 'รอจ่ายเงิน',
  WaitPartner = 'รอยืนยัน',
  ReadyForDrive = 'รอรับรถ',
  Driving = 'กำลังเช่า',
  ReturnCar = 'คืนรถแล้ว',
  Reject = 'ยกเลิก',
  Done = 'เสร็จสิ้น',
  Refunding = 'กำลังรอคืนเงิน',
  Refund = 'คืนเงินแล้ว',
}

export const mappedStatus = {
  [`${OrderStatus.Initial}`]: OrderName.Initial,
  [`${OrderStatus.WaitPayment}`]: OrderName.WaitPayment,
  [`${OrderStatus.WaitPartner}`]: OrderName.WaitPartner,
  [`${OrderStatus.ReadyForDrive}`]: OrderName.ReadyForDrive,
  [`${OrderStatus.Driving}`]: OrderName.Driving,
  [`${OrderStatus.ReturnCar}`]: OrderName.ReturnCar,
  [`${OrderStatus.Reject}`]: OrderName.Reject,
  [`${OrderStatus.Done}`]: OrderName.Done,
  [`${OrderStatus.Refunding}`]: OrderName.Refunding,
  [`${OrderStatus.Refund}`]: OrderName.Refund,
};

export const getStatusName = (status: string) => {
  /**
   * [Status]
   *    Initial: ลูกค้ากำลังสร้าง Order ยังไม่ได้เลือกวิธีการจ่ายเงิน
   *    WaitPayment: ลูกค้าเลือกวิธีการจ่ายเงินแล้ว กำลังจ่ายเงิน
   *    WaitPartner: ลูกค้าจ่ายเงินเรียบร้อยแล้ว รอ Partner กด Accept
   *    ReadyForDrive: Partner กด Accept แล้ว รอให้ลูกค้ารับรถ
   *    Driving: ลูกค้ารับรถเรียบร้อยแล้ว กำลังเช่า
   *    ReturnCar: ลูกค้าคืนรถเรียบร้อยแล้ว
   *    Reject: ยกเลิก
   *    Done: เสร็จสิ้น Order นี้ คืนรถเรียบร้อย ไม่มีปัญหาอะไร
   *    Refunding: กำลังรอคืนเงิน
   *    Refund: คืนเงินแล้ว
   */

  switch (status) {
    case OrderStatus.Initial:
      return OrderName.Initial;
    case OrderStatus.WaitPayment:
      return OrderName.WaitPayment;
    case OrderStatus.WaitPartner:
      return OrderName.WaitPartner;
    case OrderStatus.ReadyForDrive:
      return OrderName.ReadyForDrive;
    case OrderStatus.Driving:
      return OrderName.Driving;
    case OrderStatus.ReturnCar:
      return OrderName.ReturnCar;
    case OrderStatus.Reject:
      return OrderName.Reject;
    case OrderStatus.Done:
      return OrderName.Done;
    case OrderStatus.Refunding:
      return OrderName.Refunding;
    case OrderStatus.Refund:
      return OrderName.Refund;
    default:
      return 'ระบุไม่ได้';
  }
};
