
import { Actions } from '@/store/modules/CouponModule';
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { dateFormat } from '@/utils/helper/format';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

// const store = 'store';
const breadcrumbs = ['Promotions', ['Coupon List']];

export default {
  name: 'Commission List',
  components: {
    Datatable,
  },
  setup() {
    const store = useStore();
    const tableRef = ref<any>(null);
    const id = ref<any>(null);
    const route = useRoute();
    const state = reactive({
      tableData: [] as any,
      loading: false,
      total: 0,
      rowsPerPage: 10,
      currentPage: 1,
      type: 'Fix',
    });

    watch(
      () => [
        tableRef.value?.pagination.rowsPerPage,
        tableRef.value?.pagination.page,
      ],
      async ([rows, page]) => {
        state.currentPage = page;
        state.rowsPerPage = rows;

        if (state.rowsPerPage + 1 > state.total) {
          state.currentPage = 1;
        }

        await fetch(id.value, state.currentPage, state.rowsPerPage);
      }
    );

    const fetch = async (promotionId, page = 1, rowsPerPage = 10) => {
      try {
        state.loading = true;

        const { datas, total } = await store.dispatch(Actions.FETCH_COUPON, {
          promotionId,
          page,
          pageSize: rowsPerPage,
        });

        state.tableData = datas;
        state.total = total;

        state.loading = false;
      } catch (error) {
        state.loading = false;
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs(
        breadcrumbs[0] as string,
        breadcrumbs[1] as Array<Record<string, unknown> | string>,
        {}
      );

      id.value = route.params.promotionId;

      await fetch(id.value);
    });

    watch(
      () => route.params.promotionId,
      async (promotionId) => {
        if (promotionId) {
          id.value = `${promotionId}`;
          const { datas, total } = await store.dispatch(
            `${Actions.FETCH_COUPON}`,
            {
              promotionId,
            }
          );

          state.tableData = datas;
          state.total = total;
        }
      }
    );

    return {
      ...toRefs(state),
      tableHeader: computed(() => {
        return [
          {
            key: 'code',
            name: 'Code',
            sortable: false,
          },
          {
            key: 'usedCoupon',
            name: 'Used Coupon',
            sortable: false,
          },
        ];
      }),
      breadcrumbs,
      tableRef,
      dateFormat,
      async handleRadioChange(e) {
        state.type = e.target.value;

        await fetch(id.value);
      },
    };
  },
};
